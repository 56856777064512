<template>
  <div>
    <div class="d-flex justify-content-end">
      <b-button
        variant="primary"
        @click="onUploud=!onUploud"
      >
        UPLOAD FILES
      </b-button>
    </div>
    <div
      v-if="onUploud"
      class="mt-3"
    >
      <b-button
        v-show="files.length"
        variant="primary"
        class="mb-1"
        @click="onUploadFile"
      >
        Upload
      </b-button>
      <drag-and-drop
        :key="idDrag"
        v-model="files"
        :files-array="files"
      />

    </div>
    <div class="mt-3">
      <b-card
        no-body
        class="mb-0"
      >

        <b-table
          ref="information"

          show-empty
          :fields="fields"
          :items="getFiles"
        >

          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>

          <template v-slot:cell(name)="data">

            <div class="d-inline-flex align-content-center justify-content-start">

              <span>
                <amg-icon

                  v-if="data.item.extension==='docx' || data.item.extension==='doc'"
                  class="cursor-pointer text-primary"
                  icon="FileWordIcon"
                  size="20"
                />
                <amg-icon
                  v-if="data.item.extension==='pdf'"
                  class=" text-danger"
                  icon="FilePdfIcon"
                  size="20"
                />
                <amg-icon
                  v-if="data.item.extension==='xlsx'"
                  class=" text-success"
                  icon="FileExcelIcon"
                  size="20"
                />
                <amg-icon
                  v-if="data.item.extension==='ppt'||data.item.extension==='pptx'"
                  class=" text-warning"
                  icon="FilePowerpointIcon"
                  size="20"
                />
                <feather-icon
                  v-if="data.item.extension==='jpg'"
                  class=" text-info"
                  size="20"
                  icon="ImageIcon"
                />
                <tabler-icon
                  v-if="data.item.extension==='txt'"
                  class=" text-dark"
                  icon="FileDownloadIcon"
                  size="20"
                />

              </span>

              <b-link
                v-if="!saveIndex.includes(data.index)"
                class="cursor-pointer ml-1"
                @click="openFile(data.item.url)"
              >{{ data.item.file_name }}
              </b-link>

              <div
                v-else
                class="d-inline-flex mr-1 "
              >

                <b-form-input
                  v-model="data.item.file_name"
                  class="ml-1"
                  style="height: 25px"
                />
                <b-button
                  variant="outline-success"
                  class=" w-25  mr-1 ml-1"
                  size="sm"
                  @click="renameFile(data.item,data.index)"
                >SAVE
                </b-button>
                <b-button
                  variant="outline-danger"
                  size="sm"
                  class=" w-25 "
                  @click="editNames(data.index)"
                >CANCEL
                </b-button>
              </div>
            </div>
          </template>
          <template v-slot:cell(size)="data">
            <p
              class="mb-0 font-weight-bold "
            >
              {{ data.item.size + ' KB' }}
            </p>

          </template>
          <template v-slot:cell(doe)="data">
            <span
              v-if="data.item.expiration != null"
              :style="data.item.valid === 0 ? 'color:black' : data.item.valid === 1 ? 'color:#ffc107' : data.item.valid === 2 ? 'color:red' :''"
            >{{
              data.item.expiration | myGlobal
            }}</span>

          </template>
          <template v-slot:cell(upload)="data">
            <p
              class="mb-0 font-weight-bold "
            >
              {{ data.item.updated_at | myGlobalDay }}
            </p>

          </template>
          <template v-slot:cell(upload_by)="data">
            <p
              class="mb-0 font-weight-bold "
            >
              {{ data.item.user_name }}
            </p>

          </template>
          <template v-slot:cell(actions)="data">

            <feather-icon
              class="mr-1 pointer text-warning cursor-pointer"
              size="20"
              icon="EditIcon"
              @click="editNames(data.index)"
            />
            <feather-icon
              class="mr-1 pointer text-danger"
              size="20"
              icon="TrashIcon"
              @click="deleteFile(data.item.id)"
            />

          </template>
        </b-table>
      </b-card>
    </div>

  </div>
</template>

<script>
import DragAndDrop from '@/views/commons/utilities/DragAndDrop'
import { mapGetters } from 'vuex'
import dataFields
from '@/views/management/views/employees/employees-module/employees-list/modals/options/files/fields.data.js'
import EmployeesService from '@/views/management/views/employees/employees.service'

export default {
  components: {
    DragAndDrop,

  },

  data() {
    return {
      onUploud: false,
      files: [],
      fields: dataFields,
      filesData: [],
      editName: false,
      saveIndex: [],
      idDrag: 1,
    }
  },
  computed: {

    ...mapGetters({
      currentUser: 'auth/currentUser',

    }),

  },
  methods: {
    actionOnHideUploadFileModal() {
      this.files = []
    },
    editNames(index) {
      this.editName = !this.editName

      const i = this.saveIndex.indexOf(index)
      if (i > -1) this.saveIndex.splice(i, 1)
      else this.saveIndex.push(index)
    },
    async onUploadFile() {
      try {
        const result = await this.showConfirmSwal()
        if (result.isConfirmed) {
          const formData = new FormData()
          this.files.forEach(file => {
            formData.append('images[]', file, file.name)
          })
          this.addPreloader()
          formData.append('id', this.$route.params.id)
          formData.append('user_id', this.currentUser.user_id)

          const headers = {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            // eslint-disable-next-line func-names
            onUploadProgress: function (progressEvent) {
              this.uploadPercentage = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total,
              )
            }.bind(this),
          }
          const response = await amgApi.post(
            '/management/employees/save-file-and-drop-employee',
            formData,
            headers,
          )
          if (response.status === 200) {
            this.uploadFileModal = false
            this.files = []
            this.idDrag++

            this.$refs.information.refresh()
            this.showSuccessSwal()
          }
        }
      } catch (error) {
        this.showErrorSwal(error)
      } finally {
        this.removePreloader()
      }
    },
    async getFiles() {
      try {
        const params = {
          id: this.$route.params.id,
        }
        const data = await EmployeesService.getFiles(params)

        this.filesData = data.data
        this.filesData.map((data, index) => {
          this.filesData[index].file = this.filesData[index].file_name
        })

        return this.filesData
      } catch (e) {
        this.showErrorSwal(e)
        return []
      }
    },
    openFile(item) {
      window.open(
        item,
        '_blank', // <- This is what makes it open in a new window.
      )
    },
    async deleteFile(id) {
      const params = {
        id,
        user_id: this.currentUser.user_id,
      }
      try {
        const response = await this.showConfirmSwal()
        if (response.isConfirmed) {
          await EmployeesService.deleteFile(params)

          this.$refs.information.refresh()

          this.showSuccessSwal('File has been deleted successfully')
        }
      } catch (e) {
        this.showErrorSwal(e)
        return []
      }
    },

    async renameFile(item, index) {
      try {
        const params = {
          file: item.file,
          route: item.route,
          newfile: item.file_name,
          idfile: item.id,
          user_id: this.currentUser.user_id,
          extension: item.extension,
          id: this.$route.params.id,
        }
        this.addPreloader()
        const data = await EmployeesService.renameFile(params)
        if (data.status === 200) {
          this.$refs.information.refresh()
          this.removePreloader()
          this.showSuccessSwal()
          this.editNames(index)
        }
      } catch (e) {
        this.showErrorSwal(e)
        this.removePreloader()
        return []
      }
    },
  },

}
</script>

<style scoped>

</style>
