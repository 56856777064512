export default [
  {
    key: 'name',
    sortable: false,
    label: 'Name',
  },
  {
    key: 'size', sortable: false,
    label: 'Size',
  },
  {
    key: 'doe',
    sortable: false,
    label: 'DOE',
  },
  {
    key: 'upload',
    sortable: false,
    label: 'Upload',
  },
  {
    key: 'upload_by', sortable: false,
    label: 'Upload By',
  },
  {
    key: 'actions',
    sortable: false,
    label: 'Actions',
  },

]
